import 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-basemaps';
import 'leaflet-basemaps/L.Control.Basemaps.css';
import 'mapbox-gl-leaflet';
import * as mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// import * as geocoder from 'geocoder-geojson';

const map = new mapboxgl.Map({
    container: 'map',
    center: [12.375085, 51.336695],
    zoom: 12,
    pitch: 35,
    style: 'https://tiles.droidmaps.de/styles/osm-liberty-gl-style/style.json',
});

map.addControl(
    new mapboxgl.GeolocateControl({
        positionOptions: {
            enableHighAccuracy: true,
        },
        trackUserLocation: true,
    }),
);

/*map.addControl(
    new MapboxDirections({
        api: 'https://router.project-osrm.org/route/v1/',
        profile: 'driving',
        accessToken: 'none',
        geocoder: {
            localGeocoder: (query) => {
                return []; //await geocoder.wikidata(query);
            },
            localGeocoderOnly: true,
        },
    }),
    'top-left',
);*/

function rotateCamera(timestamp) {
    // clamp the rotation between 0 -360 degrees
    // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
    map.rotateTo((timestamp / 300) % 360, { duration: 0 });
    // Request the next frame of the animation.
    requestAnimationFrame(rotateCamera);
}
rotateCamera(0);

const styles = [
    {
        style: 'osm-bright',
        name: 'OSM Bright',
    },
    {
        style: 'kuldig-clean',
        name: 'KULDIG clean',
    },
    {
        style: 'oldmapsonline',
        name: 'OldMaps Online',
    },
    {
        style: 'osm-liberty-gl-style',
        name: 'OSM Liberty',
    },
    {
        style: 'positron',
        name: 'Positron',
    },
    {
        style: 'klokantech-basic',
        name: 'Basic',
    },
    {
        style: 'klokantech-3d',
        name: 'Basic 3D',
    },
    {
        style: 'fiord-color',
        name: 'Fiord',
    },
    {
        style: 'toner',
        name: 'Toner',
    },
    {
        style: 'dark-matter',
        name: 'Dark Matter',
    },
];

const baseUrl = 'https://tiles.droidmaps.de/styles/';

window.changeStyle = function changeStyle(style) {
    map.setStyle(`${baseUrl}/${style}/style.json`);
};

function generateButtonForStyleSelection(style, name) {
    return `
              <span class="w-1/2 p-2 md:w-auto flex items-center">
                  <button onclick="changeStyle('${style}')">
                      <img style="height: 125px;" src="${baseUrl}/${style}/10/547/341.png" />
                      ${name}
                  </button>
              </span>
            `;
}

const html = styles
    .map((style) => {
        return generateButtonForStyleSelection(style.style, style.name);
    })
    .join('\n');

document.getElementById('mapStyleSelection').innerHTML = html;
